#container {
    width: 100vw;
    height: 100vh;
    background-color: black;
    font-family: Roboto;
    display: flex;
}

#contentContainer {
    background-color: white;
    height: 100vh;
    display: flex;
    overflow: hidden;
    justify-content: center;
}

#loginForm {
    margin: 25px;
    align-self: center;
    width: 100%;
}

#logoContainer {
    justify-content: center;
    align-items: center;
    display: flex;
}

#logo {
    margin-bottom: 30px;
    width: 200px;
}

#header {
    align-self: flex-start;
    margin-bottom: 15px;
    color: rgb(37, 150, 190);
    font-weight: bold;
    font-size: 14px;
}

#secaoFormulario {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: transparent;
}

#labelEmail {
    color: darkblue;
    font-weight: bold;
}

#labelSenha {
    font-weight: bold;
    margin-top: 30px;
    color: darkblue;
}

#linkSenha {
    margin-top: 10px;
    font-size: 12px;
    color: rgb(37, 150, 190);
    text-decoration: none;
}

#button {
    color: white;
    background-color: rgb(51, 204, 128);
    margin-top: 5px;
    width: 100%;
}

#textoRegistro {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: rgb(50,50,50);
    margin: 0;
    margin-top: 20px;
}

#buttonRegistro {
    background-color: black;
    color: white;
    margin-top: 5px;
    width: 100%;
}

#imageContainer {
    width: 100%;
    overflow: hidden;
}

body {
    margin: 0
}
