#container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

#content {
    width: 500px;
    height: auto;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    padding-top: 2px;    
}

#header {
    width: 100%;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

#headerSeparator {
    width: 100%;
    height: 2px;
    background-color: rgba(220,220,220, 0.8);
    display: flex;
}

#headerText {
    color: rgb(51, 204, 128);
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin: auto;
    margin-left: 20px;
}

#headerText2 {
    color: rgb(50, 50, 50);
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 500;
    margin: auto;
    margin-right: 20px;
    text-decoration: none;
}

#form {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-bottom: 15px;
}

#button {
    background-color: rgb(51, 204, 128);
    color: white;
    margin-top: 15px;
}

#logo {
    width: 150px;
    align-self: center;
    margin-bottom: 10px;
}

#successText1 {
    font-family: 'Roboto', sans-serif;
    align-self: center;
    font-weight: bold;
    color: rgb(50, 50, 50)
}

#successText2 {
    font-family: 'Roboto', sans-serif;
    align-self: center;
    text-align: center;
    color: rgb(50, 50, 50)
}

#errorMessage {
    font-family: 'Roboto', sans-serif;
    align-self: center;
    color: red;
    font-size: 12px;
}

#textPwdReset {
    font-family: 'Roboto', sans-serif;
    text-align: center;
}