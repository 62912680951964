@media (max-width: 800px) {
    #selectContainer {
        width: 100%;
        background-color: white;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        padding-top: 0;
    }

    #selectContainer p {
        margin: 10px;
    }

    .selectItem {
        width: 100%;
        background-color: white;
        border-radius: 5px;
        margin-bottom: 10px;
        padding: 0;
        padding-left: 10px;
        border: solid 1px rgb(220,220,220);
    }

    #selectSelected {
        background-color: rgb(10, 132, 255);
        color: white
    }

    #buttonSubmit {
        margin-top: 20px;
        margin-left: auto;
    }
}

@media (min-width: 801px) {
    #container {
        background-color: transparent;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        justify-content: space-between;
        flex-direction: column;
    }

    .anexoContainer {
        width: 48%;
        background-color: transparent;
        align-items: center;
        justify-content: center;
        display: flex;
        margin-top: 20px;
        border-radius: 10px;
        margin: 0;
    }

    #buttonSubmit {
        margin-top: 30px;
        margin-left: auto;
        margin-bottom: 30px;
    }
}