#outerDiv {
    margin-top: 20px;
}

#header {
    font-weight: bold;
}

#anexosDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.anexoDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
}

#documentoDiv {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
}

#downloadLinkDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#downloadText {
    margin: 0;
    margin-top: 20px;
}

#uploadButton {
    margin-left: auto;
    color: white;
    background-color: black;
    margin-top: 30px;
    margin-left: 10px;
}

